<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSuppsNow()" label-width="120px">
        <el-row>
          <el-col :md="8">
            <el-form-item label="供应商编号:" prop="supp_no">
              <el-input size="small" v-model.trim="searchForm.supp_no" clearable placeholder="请填写供应商编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="供应商简称:" prop="supp_abbr">
              <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="供应商类型:" prop="supp_type">
              <el-select v-model="searchForm.supp_type" placeholder="请选择供应商类型" size="small" clearable>
                <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item prop="supp_name" label="供应商全称:">
              <el-input v-model="searchForm.supp_name" size="small" clearable placeholder="请输入供应商全称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="supp_addr" label="供应商地址:">
              <el-input v-model="searchForm.supp_addr" size="small" clearable placeholder="请输入供应商地址"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getSuppsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增
      </el-button>
      <el-button type="danger" size="small" @click="doDelete()" :disabled='!btn.delete'><i class="el-icon-delete"></i>
        删除</el-button> -->
    </div>
    <el-row>
      <el-col :md="24">
        <el-table class="vg_cursor" ref="multiTable" :data="tableData" @row-dblclick="dbClickJp" border v-loading="loadFlag">
          <el-table-column label="累计订单量" prop="supp_order_total" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.supp_ordr_total">{{ scope.row.supp_ordr_total }}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="供应商编号" prop="supp_no" align="center" />
          <el-table-column label="供应商类型" prop="supp_type" :formatter="formType" align="center" />
          <el-table-column label="供应商简称" prop="supp_abbr" align="center" />
          <el-table-column label="供应商全称" prop="supp_name" align="center" />
          <el-table-column label="供应商地址" prop="supp_addr" align="center" />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import { stffAPI } from '@api/modules/staff';
export default {
  name: 'SuppList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        page_no: 1,
        supp_no: null, //供应商编号
        supp_abbr: null, // 供应商 简称
        supp_type: null, // 供应商类型
        supp_name: null, // 供应商全称
        supp_addr: null // 供应商地址
      },
      totalPage: 0,
      btn: {},
      loadFlag: false,
      multiSelection: [],
      currentPage: 1,
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/supp_add' || from.path === '/supp_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getSuppsList();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSupps, {
        supp_no: this.searchForm.supp_no,
        supp_type: this.searchForm.supp_type,
        supp_abbr: this.searchForm.supp_abbr,
        supp_addr: this.searchForm.supp_addr,
        supp_name: this.searchForm.supp_name,
        page_no: this.searchForm.page_no
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.initData();
    },
    // 查询方法
    getSuppsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm.page_no = 1;
      this.initData();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.$emit('suppChangeId', row.supp_id);
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.initData();
    },
    // 类型转换
    formType(val) {
      if (val.supp_type === 1) {
        return '加工厂';
      } else if (val.supp_type === 2) {
        return '面料厂';
      } else if (val.supp_type === 3) {
        return '配套厂';
      } else if (val.supp_type === 4) {
        return '辅料厂';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
