<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <!--<el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>-->
        <el-button type="primary" size="small" @click="generateConfirm">生成成品采购合同</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`puin_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getPuins"
      >
        <template v-slot:supp_abbr="scope">
          <div class="vd_dis">
            <span>
              {{ scope.row.supp_abbr }}
            </span>
            <el-link type="primary" @click="replaceSupp(scope.row)">更换</el-link>
          </div>
        </template>
      </search-table>
    </el-card>
    <el-dialog title="更换供应商" :visible.sync="dialogTableVisible" width="70%">
      <SuppList @suppChangeId="suppChangeId"></SuppList>
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { puinAPI } from '@api/modules/puin';
import { stffAPI } from '@api/modules/staff';
import { custAPI } from '@api/modules/cust';
import { suppAPI } from '@api/modules/supp';
import { podrAPI } from '@/api/modules/podr';
import pubPagination from '@/components/common/pubPagination';
import SuppList from './Component/SuppList.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'PuinList',
  components: {
    SearchTable,
    pubPagination,
    SuppList
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', sortable: false },
        { prop: 'scon_cndate', itemType: 'date', label: '申购日期', formatter: val => getDateNoTime(val, true) },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称' },
        { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号' },
        { prop: 'prod_no', itemType: 'input', label: '我司货号' },
        { prop: 'prod_poststfix', itemType: 'input', label: '货号后缀', input: false, sortable: false },
        { prop: 'prod_partno', itemType: 'input', label: '部件名', input: false, sortable: false, labelWidth: '130px' },
        { prop: 'prod_cust_no', itemType: 'input', label: '客户货号' },
        { prop: 'prod_name', itemType: 'input', label: '中文名称', input: false, sortable: false },
        { prop: 'prod_spec', itemType: 'input', label: '中文规格', input: false, sortable: false },
        { prop: 'scon_part_num_reality', itemType: 'input', label: '实际数量', input: false, sortable: false },
        { prop: 'prod_unit', itemType: 'input', label: '部件单位', input: false, sortable: false },
        { prop: 'scon_part_applied', itemType: 'input', label: '已申购数量', input: false, sortable: false },
        { prop: 'scon_part_apply', itemType: 'input', label: '需申购数量', input: false, sortable: false },
        { prop: 'supp_abbr', itemType: 'input', label: '供应商', labelWidth: '120px', sortable: false },
        this.$store.state.stff_name,
        this.$store.state.create_time
      ],
      stateList: [
        { id: 0, label: '未申购/部分申购' },
        { id: 1, label: '已申购' }
      ],
      loadFlag: true,
      custList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      suppList: [],
      dialogTableVisible: false,
      sconPartIdFlag: 0
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getPuins();
      this.getStffUser();
      this.getCust();
      this.getSupp();
    },
    getPuins() {
      this.loadFlag = true;
      getNoCatch(puinAPI.getPuins, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    //获取客户信息
    getCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getPuins();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 更换供应商选择
    replaceSupp(scope) {
      this.sconPartIdFlag = scope.scon_part_id;
      this.dialogTableVisible = true;
    },
    // 确认供应商
    suppChangeId(val) {
      post(puinAPI.editPuinSupp, { scon_part_id: this.sconPartIdFlag, supp_id: val })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
      this.dialogTableVisible = false;
    },
    // 确认生成
    generateConfirm() {
      if (this.multiSelection.length > 0) {
        let temp = this.multiSelection[0].scon_id;
        let flag = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].scon_id !== temp) {
            flag = true;
          }
        }
        this.multiSelection.forEach(item => {
          if (item.prod_mainsup === '1') {
            temp++;
          }
        });
        if (flag) {
          this.$message.warning('只能销售合同号相同的数据选择');
        } else {
          let appliFlag = false;
          this.multiSelection.forEach(item => {
            if (item.scon_part_num_reality === item.scon_part_applied) {
              appliFlag = true;
            }
          });
          if (appliFlag) {
            return this.$message.warning('申购已完成');
          }
          let appliFlagC = false;
          this.multiSelection.forEach(item => {
            if (!item.supp_id) {
              appliFlagC = true;
            }
          });
          if (appliFlagC) {
            return this.$message.warning('请选择供应商简称后生成');
          }
          this.$confirm('确定生成?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              let temp = {};
              temp.scon_id = this.multiSelection[0].scon_id;
              temp.scon_part_list = this.multiSelection;
              post(podrAPI.addPodr, temp)
                .then(res => {
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: '生成成功'
                    });
                    this.initData();
                    const permId = 127;
                    this.jump('/podr_list', { perm_id: permId });
                  } else {
                    let mg = res.data.msg;
                    let tp = 'error';
                    this.$message({ message: mg, type: tp });
                  }
                })
                .catch(() => {});
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消生成'
              });
            });
        }
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 删除
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.scon_part_id);
      });
      let str = ids.join(',');
      post(puinAPI.destroyPuinsByIds, { scon_part_ids: str })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_dis {
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
  box-sizing: border-box;
}
</style>
